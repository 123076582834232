/**
 * The store contains the global state for the app, together with setter methods.
 */

import { reactive } from 'vue';
import { preferences } from '@/core/user.js';
import themes from '@/assets/styles/colors.scss';
import { isEnrolled, determineCurrentQuarter, getMutatorParams} from '@/functions/utils.js';
import { routes } from '@/router/index.js';
import { getDummyLoginData } from '@/functions/dummyLoginData.js';
import { currentEnvironment } from '@/firebase/environments';
import { updateViews } from '@/apps/views.js';

const getInitialState = () => ({
    routes,
    hasLaunched: false,
    chosenSchool: '',
    schools: [],
    studentData: [],
    currentQuarter: null,
    customProp: null, // used for passing data between sibling components
    notes: [],
    tags: [],
    freshSuccessTags: [],
    loginAnalytics: [],
    tooltip: '',
    showingGradeLevel: null,
    ui: {
        themeRules: themes,
        loadedThemes: preferences.find(e => e.key === 'colorTheme').options,
        currentColorTheme: 'default',
        colors: {},
    }
});

export const store = {
    install: (app, options) => {
        app.config.globalProperties.store = {
            debug: false,
            state: reactive(getInitialState()),
            applyUserPreferences({userPreferences}) {
                preferences.forEach(element => {
                    if (userPreferences[element.key] !== undefined && element.setFn) {
                        this[element.setFn](userPreferences[element.key]);
                    }
                });
            },
            info(...args) {
                if (this.debug) console.info(...args);
            },
            emit(...args) {
                app.config.globalProperties.ee.emit(...args);
            },
            clear() {
                this.info('clear store triggered');

                this.state.studentData = [];
                this.state.notes = [];
                this.state.tags = [];
                this.state.freshSuccessTags = [];
                this.state.customProp = null;
            },
            resetUI() {
                this.info('reset UI triggered');
                this.setColorTheme('default');
            },
            setColorTheme(newValue) {
                //Ciabatta is switching to 'Light' and 'Dark' themes. 
                //Pairing this accordingly with themes already defined in this repo.
                if (newValue === 'Light') newValue = 'default';
                if (newValue === 'Dark') newValue = 'dark';

                this.info('setColorTheme triggered with', newValue);
                this.state.ui.currentColorTheme = newValue;
                const themeNameLength = newValue.length;
                
                for (const [key, value] of Object.entries(this.state.ui.themeRules)) {
                    if (key.substring(0, themeNameLength) == newValue) {
                        this.state.ui.colors[key.substring(themeNameLength)] = value;
                    }
                }

                document.body.setAttribute('data-theme', newValue);
                this.emit('colorThemeChanged');
            },
            setLaunched(newValue) {
                this.info('setLaunched triggered with', newValue);
                this.state.hasLaunched = newValue;
            },
            setTooltip(newValue) {
                this.info('setTooltip triggered with', newValue);
                this.state.tooltip = newValue;
            },
            setGradeLevel(newValue) {
                const oldValue = this.state.showingGradeLevel;

                //update state machine
                this.info('setGradeLevel triggered with', newValue);
                this.state.showingGradeLevel = newValue;

                /** Predicted Credits */
                //get current display name for `creditsPredicted` field & update based on grade
                let displayName = app.config.globalProperties.config.fields.find(field => field.key ==='creditsPredicted').displayName;
                displayName = displayName.includes(oldValue) ? displayName.replace(oldValue, this.state.showingGradeLevel) : displayName.replace('EOY', 'G' + this.state.showingGradeLevel);

                //apply new display name to fields and fieldGroups
                app.config.globalProperties.config.fields.find(field => field.key ==='creditsPredicted').displayName = displayName;
                app.config.globalProperties.config.fieldGroups.find(group => group.name === 'Credits').fields.find(field => field.key ==='creditsPredicted').name = displayName;
                //update Summary Table views to consume new display name
                updateViews();

                this.emit('gradeLevelChange', 'gradeLevelLoaded');
            },
            setSchool(newValue) {
                this.info('setSchool triggered with', newValue);
                this.state.chosenSchool = newValue;
                this.emit('schoolChanged', newValue);
            },
            setSchools(newValue) {
                this.info('setSchools triggered with', newValue);
                this.state.schools = newValue;
            },
            setSchoolMetaData(newValue) {
                this.info('setSchoolMetaData triggered with', newValue);
                this.state.schoolMetaData = newValue;
            },
            setCalendarEvents(newValue) {
                this.info('setCalendarEvents triggered with', newValue);
                this.state.calendarEvents = newValue;
            },
            setCompletedSDCSteps(newValue) {
                this.info('setCompletedSDCSteps triggered with', newValue);
                this.state.schoolMetaData.completedSDCSteps = newValue;
            },
            setNotes(newValue) {
                this.info('setNotes triggered with', newValue);
                this.state.notes = newValue;
                this.emit('noteDataReceived', 'noteDataLoaded');
            },
            setTags(newValue) {
                this.info('setTags triggered with', newValue);
                this.state.tags = newValue.sort((a, b) => a.title > b.title ? 1 : -1);
                this.emit('tagDataReceived', 'tagDataLoaded');
            },
            setFreshSuccessTags(newValue) {
                this.info('setFreshSuccessTags triggered with', newValue);
                this.state.freshSuccessTags = newValue.sort((a, b) => a.title > b.title ? 1 : -1);
                this.emit('freshSuccessTagDataReceived', 'freshSuccessTagDataLoaded');
            },
            toggleMyTag({id, icon}) {
                const myStudent = this.state.studentData.find(record => record.studentRISEId == id);

                if (myStudent['_myTags'].includes(icon)) {
                    const i = myStudent['_myTags'].indexOf(icon);
                    myStudent['_myTags'].splice(i, 1);
                } else {
                    myStudent['_myTags'].push(icon);
                }
            },

            /**
             * @param {string} newValue - expecting either: Q1, Q2, Q3, Q4, EOY.
             * 
             * Current Quarter is set from school meta data: meta.currentMarkingPeriod
             * It is expecting an integer output of either 1, 2, 3, or 4.
             * Since EOY is not used for anything yet, we are defaulting to Q4.
             * EOY is the period between Q4 and Q1 of the next year.
             */
            setCurrentQuarter(newValue) {                
                if (newValue) newValue = newValue === 'EOY' ?  4 : newValue.slice(1);
                this.info('setCurrentQuarter triggered with', newValue);
                this.state.currentQuarter = newValue;
            },
            setStudentData(newValue) {
                this.info('setStudentData triggered with', newValue);

                const myData = this.applyMutators(newValue);

                this.state.studentData = myData
                    .filter(record => isEnrolled(record))
                    .sort((a, b) => a.lastName > b.lastName ? 1 : -1);
                
                this.emit('studentDataReceived', 'studentDataLoaded');
            },
            applyMutators(data) {

                const params = getMutatorParams({
                    state: this.state,
                    user: app.config.globalProperties.$user
                });

                app.config.globalProperties.config.fields.forEach(field => {
                    if (field.mutator) {
                        data.forEach(record => {
                            record[field.key] = field.mutator({data: record, params});
                        });
                    }
                });

                return data;
            },
            setCustomProp(newValue) {
                this.info('setCustomProp triggered with', newValue);
                this.state.customProp = newValue;
            },
            async initializeLoginAnalytics() {
                // if we're in production, read the database, otherwise get dummy data
                const _loginData = ['production', 'qa'].some(element => element === currentEnvironment.key) ?
                    await app.config.globalProperties.fb.analytics.getAll():
                    getDummyLoginData();
    
                // filter out test objects in database
                const loginData = _loginData.filter(element => !!element.weekStartingDate);
    
                this.setLoginAnalytics(loginData);        
            },
            setLoginAnalytics(newValue) {
                this.info('setLoginAnalytics triggered with', newValue);
                this.state.loginAnalytics = newValue;
            }
        };
    }
};

export const initializeColorTheme = {
    install: (app, options) => {
        app.config.globalProperties.store.setColorTheme('default');
    }
};
