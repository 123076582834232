/**
 * This file contains all of the methods for interfacing with Firebase document database, accessible as this.fb in the vue app.
 */

require('firebase/auth');
import 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, onSnapshot, addDoc, getDoc, getDocs, doc, deleteDoc, setDoc, updateDoc, where } from 'firebase/firestore';
import { validate } from '@/firebase/validate.js';
import { firebaseConfig } from '@/firebase/environments.js';
import { currentEnvironment } from '@/firebase/environments';
import studentSchema from '@/schemas/studentSchema.json';
import notesSchema from '@/schemas/notesSchema.json';
import userSchema from '@/schemas/userSchema.json';
import eventsSchema from '@/schemas/eventsSchema.json';

// Initialize Firebase

const _app = initializeApp(firebaseConfig);
export const db = getFirestore(_app);

// Firebase plugin

export const installFB = (app, options) => {

    const databases = currentEnvironment.databases;
    const schemas = {
        main: studentSchema,
        notes: notesSchema,
        user: userSchema,
        events: eventsSchema
    };

    const getPath = () => [
        db, 
        currentEnvironment.schoolCollection, 
        app.config.globalProperties.store.state.chosenSchool
    ];

    // this will be available as this.fb from every component
    app.config.globalProperties.fb = {
        dataSources: [
            {
                key: 'notes',
                database: databases.notes,
                unsubscribeFn: 'unsubscribeNotes',
                setDataFn: 'setNotes'
            },
            {
                key: 'tags',
                database: databases.tags,
                unsubscribeFn: 'unsubscribeTags',
                setDataFn: 'setTags'
            },
            {
                key: 'freshSuccessTags',
                database: databases.freshSuccessTags,
                unsubscribeFn: 'unsubscribeFreshSuccessTags',
                setDataFn: 'setFreshSuccessTags'
            },
            {
                key: 'main',
                database: databases.main,
                unsubscribeFn: 'unsubscribe',
                setDataFn: 'setStudentData'
            },
        ],
        getQuery({
            source,
            currentGradeLevel = app.config.globalProperties.store.state.showingGradeLevel
        }) {
            //databases.main is for getting student data
            if (source.database == databases.main) {
                return query(
                    collection(...getPath(), source.database),
                    where('currentGradeLevel', '==', currentGradeLevel)
                );
            }
            if (source.database == databases.notes) {
                const user = app.config.globalProperties.$user;
                if (user.hasPower('viewAllCheckins')) {
                    return query(
                        collection(...getPath(), source.database)
                    );
                } else {
                    return query(
                        collection(...getPath(), source.database),
                        where('uid', '==', user.uid)
                    );
                }
            }
            // for tags database
            return query(
                collection(...getPath(), source.database)
            );
        },
        subscribe() {
            this.dataSources.forEach(source => {
                const q = this.getQuery({source});

                this[source.unsubscribeFn] = onSnapshot(q, querySnapshot => {
                    const result = [];
                    querySnapshot.forEach(doc => {
                        const myRecord = doc.data();
                        myRecord.docID = doc.id;
                        result.push(myRecord);
                    });
                    app.config.globalProperties.store[source.setDataFn](result);
                });
            });
        },
        unsubscribeAll() {
            if (!app.config.globalProperties.store.state.hasLaunched) return;
            this.dataSources.forEach(source => {
                if (this[source.unsubscribeFn] !== undefined) {
                    this[source.unsubscribeFn]();
                }
            });
        },
        messages: {
            add (message) {
                if (validate(schemas.notes, message)) {
                    addDoc(collection(...getPath(), databases.notes), message);
                }
            },
            delete (message) {
                deleteDoc(doc(...getPath(), databases.notes, message.docID));
            },
            update (id, message) {
                if (validate(schemas.notes, message)) {
                    setDoc(doc(...getPath(), databases.notes, id), message);
                }
            }
        },
        tags: {
            add (tag) {
                addDoc(collection(...getPath(), databases.tags), tag);
            },
            delete (tag) {
                deleteDoc(doc(...getPath(), databases.tags, tag.docID));
            },
            update (id, tag) {
                setDoc(doc(...getPath(), databases.tags, id), tag);
            }
        },
        freshSuccessTags: {
            add (tag) {
                addDoc(collection(...getPath(), databases.freshSuccessTags), tag);
            },
            delete (tag) {
              deleteDoc(doc(...getPath(), databases.freshSuccessTags, tag.docID));
            },
            update (id, tag) {
                setDoc(doc(...getPath(), databases.freshSuccessTags, id), tag);
            }
        },
        studentData: {
            update ({id, record, fieldKey}) {
                if (validate(schemas.main, record)) {
                    updateDoc(doc(...getPath(), databases.main, id), {[fieldKey]: record[fieldKey]});
                }
            },
        },
        user: {
            updatePreference (key, newValue) {
                app.config.globalProperties.$user.preferences[key] = newValue;

                const preferences = {...app.config.globalProperties.$user.preferences};
                if (typeof preferences.customViews !== 'string') {
                    preferences.customViews = JSON.stringify(preferences.customViews);
                }

                updateDoc(
                    doc(db, databases.users, app.config.globalProperties.$user.uid), 
                    { preferences }
                );
            },
            agreeToDataPolicy() {
                const agreeToDataPolicy = true;
                updateDoc(
                    doc(db, databases.users, app.config.globalProperties.$user.uid), 
                    { agreeToDataPolicy }
                );
            },
            async getMyself(uid) {
                const docRef = doc(db, databases.users, uid);
                const docSnap = await getDoc(docRef);

                if (!docSnap.exists()) console.error('No user document found!');

                return docSnap.data();
            },
        },
        schools: {
            async getSchoolList(userSchools) { 
                const schools = [];
                
                //PULL ONLY MY SCHOOLS  
                for (const school of userSchools) {
                    const fbSchool = await this.getSchool(school);
                    if (fbSchool) {
                        schools.push({
                            'displayName': fbSchool.displayName,
                            'key': fbSchool.key,
                        });
                    }
                }
                
                return schools;
            },
            async getSchool(school) {
                const fbSchool = await getDoc(doc(db, currentEnvironment.schoolCollection, school));
                return fbSchool.exists() ? fbSchool.data() : null;
            },
            async getMetaData() {
                const docSnap = await getDoc(doc(...getPath()));
                if (docSnap.exists()) {
                    return docSnap.data();
                } else {
                    console.error('No school meta data found');
                    return [];
                }
            },
            updateField({key, newValue}) {
                updateDoc(
                    doc(...getPath()),
                    { [key]: newValue }
                );
            }
        },
        calendar: {
            async getAll () {
                const events = await getDocs(collection(db, databases.calendar)).catch(error => console.error('Could not get calendar events' + error));
                const result = [];
                events.forEach(doc => {
                    const obj = doc.data();
                    obj.docID = doc.id;
                    result.push(obj);
                });
                return result;
            },
            add (event) {
                addDoc(collection(db, databases.calendar), event);
            },
            delete (id) {
                deleteDoc(doc(db, databases.calendar, id));
            },
            async update (id, event) {
                await setDoc(doc(db, databases.calendar, id), event);
            }
        },
        events: {
            async add (event) {
                if (validate(schemas.events, event)) {
                    await addDoc(collection(db, databases.events), event).catch(error => console.error('Could not add event' + error));
                }
            },
        },
        analytics: {
            async getAll () {
                const reports = await getDocs(collection(db, databases.analytics));
                const result = [];
                reports.forEach(doc => {
                    const obj = doc.data();
                    obj.docID = doc.id;
                    result.push(obj);
                });
                return result.sort((a, b) => a.weekStartingDate > b.weekStartingDate ? 1 : -1);
            },
        }
    };
};
