/**
 * Main fields configuration file for the student documents in the app. Each object in this array configures how the field is used in the app.
 * Some of the field attributes are necessary for Tabulator.
 */

import { formatters, stepFormatter, logFormatter, bandsFormatter, ADAbandsFormatter, onTrackFormatter, ADAProfileFormatter, GPAProfileFormatter } from '@/functions/formatters.js';
import { GPABands, ADABands, suspensionsBands, creditBands, absenceBands, checkInBands } from '@/apps/fieldBands.js';
import { groups } from '@/apps/fieldGroups.js';
import { stepOptions, stepOrder, onTrackOptions, onTrackOrder } from '@/apps/fieldOptions.js';
import { coursesToSVG } from '@/functions/coursesSVG';
import { processFieldsAndGroups, getFirst, logSort } from '@/functions/utils';
import { mutators, sorters } from './fieldMutators';

/*
Field Attributes - Handy reference

bands:                      For fields with type 'numeric', bands for data to be displayed in, e.g. 0-3, 4-5, 6+ passing courses.
displayName:                Field name as it appears to the user
formatter:                  How this field should be displayed in Tabulator. Function can return a string or html.
frozen:                     Tabulator property - freeze column at left of table
headerFilter:               Tabulator property - boolean or string - should show header filter text input
headerSort:                 Tabulator property - boolean, default true - if set to false, table column cannot be sorted
hideHeaderFilterIcon:       Don't show filter icon at top of table
hideInAnalytics:            Don't allow this field to be chosen in Custom Charts page
hideInCounselorView:        Don't show this field in the counselor view of Student Profile page
hideInFilters:              Don't show this field in the filters drop-down or Custom Charts field selector
hideInsights:               Don't show the field insights icon at top of table
hozAlign:                   Tabulator property
key:                        Field key (very important) - Corresponds to key in document database. keys beginning with underscore indicate keys that do not exist in document database
logField:                   Is this a user-editable field in the form of a log?
mutator:                    For calculated fields that don't exist in the document database, the mutator runs when student data is received. Its parameters are set by Tabulator.
noHeaderMenu:               Hide all header icons at the top of the table
optionGroup:                (not set here. Assigned to the field automatically to categorize it in the dropdown menus. Set this value in 'groups' below)
options:                    For fields with type 'category', possible values a field may have and how to display those values.
order:                      How to order the options of a category field
readOnly:                   A user cannot update this field
showHeaderFilterTextInput:  Instead of the three icons at the top of the table, show a text input for filtering the column 
showInGrades:               Which grades is this field relevant to?
sorter:                     How tabulator will sort the data using the sort arrow. If this is blank it might not sort
sorterParams:               Any sort Parameters that need to be passed into Tabulator
type:                       Type of field. Can be one of 'unique', 'category', 'tags', 'boolean', 'numeric', 'categoryArray', 'courses', 'date'
userDescription:            Extra explanatory information shown to the user about this field
width:                      Tabulator property - width of column
*/

const _fields = [
    // _studentName
    {
        key: '_studentName', // keys beginning with underscore indicate keys that do not exist in document database
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Student Name',
        userDescription: 'Student first and last name.',
        type: 'unique',
        logField: false,
        width: 150,
        frozen: true,
        headerFilter: true,
        showHeaderFilterTextInput: true,
        readOnly: true,
        hozAlign: 'left',
        mutator: mutators.studentName,
    },
    // _myTags
    {
        key: '_myTags', 
        showInGrades: [9, 10, 11, 12],
        displayName: 'My Tags',
        width: 140,
        userDescription: 'Use this field to group students into custom lists. Only you can see these tags.',
        type: 'category',
        logField: false,
        readOnly: false,
        hideInsights: true,
        hideInCounselorView: true,
        hideInAnalytics: true,
        mutator: mutators.myTags,
    },    
    // tags
    {
        key: 'tags',
        showInGrades: [11, 12],
        displayName: 'Tags',
        userDescription: 'Add or remove school-wide postsecondary tags to a student.',
        type: 'tags',
        noHeaderMenu: true,
        hideInCounselorView: true,
        logField: true,
        width: 180,
        readOnly: false,
        headerFilter: 'input',
        showHeaderFilterTextInput: true,
        headerSort: false,
        sorter: sorters.tags
    },
    // studentDistrictId
    {
        key: 'freshSuccessTags',
        showInGrades: [9, 10, 11 ,12],
        displayName: 'Freshmen Success Tags',
        userDescription: 'Add or remove school-wide freshmen success tags to a student.',
        type: 'tags',
        noHeaderMenu: true,
        hideInCounselorView: true,
        logField: true,
        width: 180,
        readOnly: false,
        headerFilter: 'input',
        showHeaderFilterTextInput: true,
        headerSort: false,
        sorter: sorters.tags
    },
    {
        key: 'studentDistrictId',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Student ID',
        userDescription: 'Student district identification number.',
        type: 'unique',
        logField: false,
        readOnly: true,
        headerFilter: true,
        showHeaderFilterTextInput: true,
    },
    // email
    {
        key: 'email',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Student Email',
        userDescription: 'Student email information comes from the student survey and can be updated by app users.',
        type: 'unique',
        readOnly: false,
        formatter: logFormatter,
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // counselorName
    {
        key: 'counselorName',
        showInGrades: [9, 10, 11, 12],
        displayName: 'Counselor',
        userDescription: 'Student postsecondary counselor.',
        type: 'category',
        logField: false,
        readOnly: true,
    },
    // counselorEmail
    {
        key: 'counselorEmail',
        showInGrades: [9, 10, 11, 12],
        displayName: 'Counselor Email',
        userDescription: 'Postsecondary counselor Email',
        hideInFilters: true,
        type: 'unique',
        logField: false,
        readOnly: true,
    },
    // OTCName
    {
        key: 'OTCName',
        showInGrades: [9, 10, 11, 12],
        displayName: 'OTC',
        userDescription: 'On-Track Coordinator.',
        type: 'category',
        logField: false,
        readOnly: true,
        hideInCounselorView: true,
        sorter: 'string',
        sorterParams: {
            alignEmptyValues: 'bottom'
        }
    },
    // OTCEmail
    {
        key: 'OTCEmail',
        showInGrades: [9, 10, 11, 12],
        displayName: 'OTC Email',
        hideInFilters: true,
        userDescription: 'On-Track Coordinator email.',
        type: 'unique',
        logField: false,
        readOnly: true,
    },
    // schoolTeam
    {
        key: 'schoolTeam',
        showInGrades: [9, 10, 11, 12],
        displayName: 'School Team',
        userDescription: 'Student\'s School Team',
        type: 'category',
        logField: false,
        readOnly: true,
        sorter: 'string'
    },
    // currentGradeLevel
    {
        key: 'currentGradeLevel',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Current Grade',
        userDescription: 'Current grade level of student.',
        readOnly: true,
        logField: false,
        type: 'category'
    },
    // cohort
    {
        key: 'cohort',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Cohort',
        userDescription: 'Student high school graduating class',
        readOnly: true,
        logField: false,
        type: 'category'
    },
    // gender
    {
        key: 'gender',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Gender',
        userDescription: 'Gender',
        type: 'category',
        readOnly: true,
        logField: false,
    },
    // raceAndEthnicity.race
    {
        key: 'raceAndEthnicity.race',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Race / Ethnicity',
        userDescription: 'Race / Ethnicity',
        type: 'category',
        logField: false,
        readOnly: true,
    },
    // englishLearner
    {
        key: 'englishLearner',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'English Learner',
        userDescription: 'Student ELL status',
        type: 'boolean',
        logField: false,
        options: {
            true: 'ELL',
            false: 'Not ELL'
        },
        readOnly: true,
        formatter: ({cell, colors}) => formatters.bool(cell.getValue(), 'ELL', 'Not ELL', colors)
    },
    // entryDate
    {
        key: 'entryDate',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Entry Date',
        userDescription: 'Student current year entry date',
        readOnly: true,
        type: 'date',
        logField: false
    },
    // specialEducation
    {
        key: 'specialEducation',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Special Ed',
        userDescription: 'Student Special Ed status',
        type: 'boolean',
        logField: false,
        options: {
            true: 'Sped',
            false: 'Not Sped'
        },
        readOnly: true,
        formatter: ({cell, colors}) => formatters.bool(cell.getValue(), 'Sped', 'Not Sped', colors)
    },
    // firstGeneration
    {
        key: 'firstGeneration',
        showInGrades: [11, 12],
        displayName: 'First Generation',
        userDescription: 'This data comes from student surveys, and may be updated by app users.',
        type: 'boolean',
        options: {
            true: 'First Generation',
            false: 'Not First Generation'
        },
        readOnly: false,
        formatter: ({cell, colors}) => formatters.checkify(getFirst(cell), colors),
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // school.district
    {
        key: 'school.district',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'District',
        userDescription: 'The student’s school district',
        readOnly: true,
        type: 'category',
        logField: false,
    },
    // creditsEarned
    {
        key: 'creditsEarned',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Credits Earned',
        width: 140,
        userDescription: 'Credits that the student has already earned.',
        readOnly: true,
        type: 'numeric',
        logField: false,
    },
    // school.name
    {
        key: 'school.name',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'School',
        userDescription: 'The student’s high school',
        readOnly: true,
        type: 'category',
        logField: false,
    },
    // _planStatus
    {
        key: '_planStatus', 
        showInGrades: [11, 12],
        displayName: 'Plan status',
        userDescription: `This data shows whether the student's postsecondary plan is confirmed, unsure, or missing.`,
        hideInCounselorView: true,
        hideInFilters: true,
        type: 'category',
        logField: false,
        readOnly: true,
        mutator: mutators.planStatus,
    },
    // plans
    {
        key: 'plans',
        displayName: 'Active Plan', 
        userDescription: 'Student\'s postsecondary plan',
        showInGrades: [11, 12],
        type: 'category',
        width: 160,
        options: {
            'No Plan': { displayValue: 'No Plan', style: 'warning' },
            '4-Year College': { displayValue: '4-Year College', style: 'success' },
            '2-Year College': { displayValue: '2-Year College', style: 'success' },
            'Trade or Technical': { displayValue: 'Trade or Technical', style: 'success' },
            'Military': { displayValue: 'Military', style: 'success' },
            'Alternative Plan': { displayValue: 'Transition Plan', style: 'success' },
            'Workforce': { displayValue: 'Workforce', style: 'success' },
            'Certification': { displayValue: 'Certification', style: 'success' },
            'Unsure': { displayValue: 'Unsure', style: 'info' },
            'Non-grad': { displayValue: 'Non-grad', style: 'contrastMedium' }
        },
        //Our color groupings in Custom charts is hard to read when most of it is green.
        //Setting different options for when it is selected in the 'Color Grouping' dropdown.
        //These styles are using JS camel case variable names 
        colorGroupingOptions: {
            '4-Year College': { displayValue: '4-Year College', style: 'gradeA' },
            '2-Year College': { displayValue: '2-Year College', style: 'gradeB' },
            'Trade or Technical': { displayValue: 'Trade or Technical', style: 'lightSecondaryContainer' },
            'Military': { displayValue: 'Military', style: 'secondary' },
            'Workforce': { displayValue: 'Workforce', style: 'secondaryLight' },
            'Alternative Plan': { displayValue: 'Transition Plan', style: 'contrastLowish' },
            'Certification': { displayValue: 'Certification', style: 'alert' },
            'Unsure': { displayValue: 'Unsure', style: 'info' },
            'No Plan': { displayValue: 'No Plan', style: 'warning' },
            'Non-grad': { displayValue: 'Non-grad', style: 'contrastHigh' }
        },
        order: ['4-Year College', '2-Year College', 'Trade or Technical', 'Military', 'Workforce', 'Alternative Plan', 'Certification', 'Unsure', 'No Plan', 'Non-grad'],
        readOnly: false,
        formatter: ({cell, options, colors}) => {
            let value = getFirst(cell);
            return formatters.customStyle(value, options, colors);
        },
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // alternatePlans
    {
        key: 'alternatePlans',
        showInGrades: [11, 12],
        displayName: 'Alternate Plans',
        userDescription: 'Student\'s alternative postsecondary plan',
        width: 160,
        options: {
            'No Plan' : 'No Plan',
            '4-Year College': '4-Year College',
            '2-Year College': '2-Year College',
            'Trade or Technical': 'Trade or Technical',
            'Military': 'Military',
            'Alternative Plan': 'Transition Plan',
            'Workforce': 'Workforce',
            'Certification': 'Certification',
            'Unsure': 'Unsure'
        },
        type: 'categoryArray',
        readOnly: false,
        formatter: ({cell, colors}) => {
            const value = getFirst(cell);
            if (value === null) return formatters.nullWarning(colors);
            return typeof value === 'string' ?
                value.replace(',', ', '):
                value
        },
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // g8Credits
    {
        key: 'g8Credits',
        showInGrades: [9, 10, 11, 12],
        displayName: 'G8 Credits',
        userDescription: 'High school credits earned in grade 8',
        type: 'numeric',
        readOnly: true,
        logField: false,
    },
    // g9Credits
    {
        key: 'g9Credits',
        showInGrades: [10, 11, 12],
        displayName: 'G9 Credits',
        userDescription: 'Credits earned in grade 9',
        type: 'numeric',
        readOnly: true,
        logField: false,
        bands: creditBands,
        formatter: bandsFormatter
    },
    // g10Credits
    {
        key: 'g10Credits',
        showInGrades: [11, 12],
        displayName: 'G10 Credits',
        userDescription: 'Credits earned in grade 10',
        type: 'numeric',
        readOnly: true,
        logField: false,
        bands: creditBands,
        formatter: bandsFormatter
    },
    // g11Credits
    {
        key: 'g11Credits',
        showInGrades: [12],
        displayName: 'G11 Credits',
        userDescription: 'Credits earned in grade 11',
        type: 'numeric',
        readOnly: true,
        logField: false,
        bands: creditBands,
        formatter: bandsFormatter
    },
    // g12Credits
    {
        key: 'g12Credits',
        showInGrades: [],
        displayName: 'G12 Credits',
        userDescription: 'Credits earned in grade 12',
        type: 'numeric',
        readOnly: true,
        logField: false,
        bands: creditBands,
        formatter: bandsFormatter
    },
    // creditsPredicted
    {
        key: 'creditsPredicted',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Predicted EOY Credits',
        userDescription: 'The credits from the current academic year a student will have at the end of the year if they keep their current grades in all classes. Excludes courses that have not yet started.',
        type: 'numeric',
        hideInFilters: false,
        hideInCounselorView: true,
        hideInAnalytics: false,
        width: 130,
        readOnly: true,
        logField: false,
        bands: [
            {low: 0, high: 3.99, style: 'warning', label: '0 - 3.99'},
            {low: 4, high: 5.99, style: 'info', label: '4-5.99'},
            {low: 6, high: 6.49, style: 'gradeB', label: '6-6.49'},
            {low: 6.5, high: 100, style: 'success', label: '6.5+'},
        ],
        formatter: bandsFormatter
    },
    // graduationConcerns
    {        
        key: 'graduationConcerns',
        showInGrades: [12],
        displayName: 'Graduation Concerns',
        userDescription: 'This field allows app users to flag students as having graduation concerns.',
        type: 'boolean',
        readOnly: false,
        options: {
            true: 'Concerns',
            false: 'No Concerns'
        },
        formatter: ({cell, colors}) => {
            const value = getFirst(cell);
            return formatters.warnify(value, value == true, colors);
        },
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // GPA
    {
        key: 'GPA',
        showInGrades: [9, 10, 11, 12],
        displayName: 'GPA',
        userDescription: 'Unofficial student cumulative GPA. This GPA is unweighted, i.e. honors classes are not given extra weight. Do not use for official reporting purposes.',
        type: 'numeric',
        readOnly: true,
        logField: false,
        bands: GPABands,
        formatter: bandsFormatter,
        profileFormatter: GPAProfileFormatter
    },
    // courses
    {
        key: 'courses',
        showInGrades: [8, 9, 10, 11, 12],
        displayName: 'Grades',
        type: 'courses',
        hideInCounselorView: true,
        hideInsights: true,
        hideHeaderFilterIcon: true,
        logField: false,
        userDescription: 'Student courses and grades. The 4x3 grid shows a visual summary of grades for each quarter. The top row is the highest grade; the middle row is the median grade; the bottom row is the lowest grade.',
        readOnly: true,
        headerSort: false,
        mutator: mutators.droppedCourses,
        formatter: function ({cell, colors}) {
            const value = cell.getValue();
            if (!value) return formatters.nullWarning(colors);
            return coursesToSVG(value);
        },
        accessorDownload: function (value) {
            return '';
        }
    },
    //passingCourseCount
    {
        key: 'passingCourseCount',
        showInGrades: [9, 10, 11, 12],
        displayName: 'This Quarter Passing Courses',
        userDescription: 'Number of Passing Courses this quarter.',
        type: 'numeric',
        readOnly: true,
        logField: false,
        width: 145,
        bands: [
            {low: -1, high: 3, style: 'warning', label: '< 4'},
            {low: 4, high: 5, style: 'info', label: '4-5'},
            {low: 6, high: 100, style: 'success', label: '6+'},
        ],
        formatter: bandsFormatter
    },
    // onTrackStatus (Year On-track)
    {
        key: 'onTrackStatus',
        showInGrades: [9, 10, 11, 12],
        displayName: 'Year On-Track',
        userDescription: 'Students are "on track" if they have passed or are currently passing enough classes to get them the credits they need to pass their current year of high school.',
        // hideInFilters: true,
        hideInCounselorView: true,
        hideInAnalytics: false,
        type: 'category',
        formatter: onTrackFormatter,
        options: onTrackOptions,
        order: onTrackOrder,
        readOnly: true,
        logField: false,
        sorter: 'string'
    },
    // _onTrackPlus
    {
        key: '_onTrackPlus',
        showInGrades: [],
        displayName: 'Y1 On-Track Status with GPA',
        userDescription: 'Grade 9 On-Track Status with GPA',
        hideInFilters: true,
        hideInCounselorView: true,
        hideInAnalytics: true,
        type: 'category',
        formatter: onTrackFormatter,
        options: {
                'off-track': { displayValue: 'Off-Track', style: 'warning'},
                'almost on-track': { displayValue: 'Almost On-Track', style: 'info'},
                'on-track': { displayValue: 'On-Track', style: 'gradeB'},
                'on-track-plus': { displayValue: 'On-Track with GPA > 3.0', style: 'success'},
            },
        order: ['Off-Track', 'Almost On-Track', 'On-Track', 'On-Track with GPA > 3.0'],
        mutator: mutators.onTrackPlus,
        readOnly: true,
        logField: false,
    },
    // S1OnTrackStatus
    {
        key: 'S1OnTrackStatus',
        showInGrades: [9, 10, 11, 12],
        displayName: 'S1 On-Track',
        userDescription: 'Students are "on-track" or "almost on-track" based on a district-set threshold number of classes.',
        hideInCounselorView: true,
        hideInAnalytics: false,
        type: 'category',
        formatter: onTrackFormatter,
        options: onTrackOptions,
        order: onTrackOrder,
        readOnly: true,
        logField: false,
        sorter: 'string'
    },
    // S2OnTrackStatus
    {
        key: 'S2OnTrackStatus',
        showInGrades: [9, 10, 11, 12],
        displayName: 'S2 On-Track',
        userDescription: 'Students are "on-track" or "almost on-track" based on a district-set threshold number of classes."',
        hideInCounselorView: true,
        hideInAnalytics: false,
        type: 'category',
        formatter: onTrackFormatter,
        options: onTrackOptions,
        order: onTrackOrder,
        readOnly: true,
        logField: false,
        sorter: 'string'
    },
    // Q1OnTrackStatus
    {
        key: 'Q1OnTrackStatus',
        showInGrades: [9],
        displayName: 'Q1 On-Track',
        userDescription: 'Q1 On-Track Status: A student is considered On-Track based on district-set criteria for their grade level.',
        hideInFilters: false,
        type: 'category',
        formatter: onTrackFormatter,
        options: onTrackOptions,
        order: onTrackOrder,
        readOnly: true,
        logField: false,
        sorter: 'string'
    },
    // Q2OnTrackStatus
    {
        key: 'Q2OnTrackStatus',
        showInGrades: [9],
        displayName: 'Q2 On-Track',
        userDescription: 'Q2 On-Track Status: A student is considered On-Track based on district-set criteria for their grade level.',
        hideInFilters: false,
        type: 'category',
        formatter: onTrackFormatter,
        options: onTrackOptions,
        order: onTrackOrder,
        readOnly: true,
        logField: false,
        sorter: 'string'
    },
    // Q3OnTrackStatus
    {
        key: 'Q3OnTrackStatus',
        showInGrades: [9],
        displayName: 'Q3 On-Track',
        userDescription: 'Q3 On-Track Status: A student is considered On-Track based on district-set criteria for their grade level.',
        hideInFilters: false,
        type: 'category',
        formatter: onTrackFormatter,
        options: onTrackOptions,
        order: onTrackOrder,
        readOnly: true,
        logField: false,
        sorter: 'string'
    },
    // Q4OnTrackStatus
    {
        key: 'Q4OnTrackStatus',
        showInGrades: [9],
        displayName: 'Q4 On-Track',
        userDescription: 'Q4 On-Track Status: A student is considered On-Track based on district-set criteria for their grade level.',
        hideInFilters: false,
        type: 'category',
        formatter: onTrackFormatter,
        options: onTrackOptions,
        order: onTrackOrder,
        readOnly: true,
        logField: false,
        sorter: 'string'
    },
    // _currentQuarterOnTrackStatus
    {
        key: '_currentQuarterOnTrackStatus',
        showInGrades: [9],
        displayName: 'This Quarter On-Track',
        userDescription: 'Current Quarter On-Track Status: A student is considered On-Track based on district-set criteria for their grade level.',
        width: 150,
        type: 'category',
        readOnly: true,
        options: onTrackOptions,
        order: onTrackOrder,
        mutator: mutators.currentQuarterOnTrackStatus,
        formatter: onTrackFormatter,
        logField: false,
        sorter: 'string'
    },
    // _onTheCusp
    {
        key: '_onTheCusp',
        showInGrades: [9, 10, 11, 12],
        displayName: 'On-the-cusp',
        userDescription: 'Student has a current course with a percentage grade within 10% of the passing threshold.',
        type: 'boolean',
        readOnly: true,
        hideInCounselorView: true,
        options: {
            true: 'On-the-cusp',
            false: 'Not on-the-cusp'
        },
        mutator: mutators.onTheCusp,
        formatter: ({cell, colors}) => {
            const value = cell.getValue();
            return formatters.warnify(value, value == true, colors);
        },
        logField: false,
    },
    // _onTheCuspCourses
    {
        key: '_onTheCuspCourses',
        showInGrades: [9, 10, 11, 12],
        displayName: 'On-the-cusp Courses',
        hideInsights: true,
        hideHeaderFilterIcon: true,
        hideInCounselorView: true,
        width: 180,
        userDescription: 'List of current courses with a percentage grade within 10% of the passing threshold.',
        type: 'boolean',
        readOnly: true,
        mutator: mutators.onTheCuspCourses,
        logField: false,
        formatter: ({cell}) => {
            const value = cell.getValue();
            const el = document.createElement('span');
            el.innerText = value;
            el.setAttribute('title', value);
            return el;
        }
    },    
    // _climberSlider
    {
        key: '_climberSlider',
        showInGrades: [9],
        displayName: 'Climber / Slider',
        userDescription: 'Student has become On-Track or Off-Track (by passing classes) since last quarter',
        type: 'category',
        readOnly: true,
        options: {
            'Climber': {displayValue: 'Climber', style: 'success'},
            'Slider': {displayValue: 'Slider', style: 'warning'},
            'Unchanged': {displayValue: 'Unchanged', style: 'contrastLow'},
        },
        order: ['Slider', 'Unchanged', 'Climber'],
        mutator: mutators.climberSlider,
        formatter: ({cell, options, colors}) => {
            const value = cell.getValue();
            return formatters.customStyle(value, options, colors);
        },
        logField: false,
    },
    // SATScores.EBRW
    {
        key: 'SATScores.EBRW',
        showInGrades: [9, 10, 11, 12],
        displayName: 'SAT EBRW',
        userDescription: 'Student\'s SAT EBRW score.',
        type: 'numeric',
        readOnly: true,
        logField: false,
        bands: [
            // Using benchmark values from College Board
            // https://satsuite.collegeboard.org/k12-educators/about/understand-scores-benchmarks/benchmarks
            {low: -1, high: 450, style: 'warning', label: '200-450'},
            {low: 460, high: 470, style: 'info', label: '460-470'},
            {low: 480, high: 1000, style: 'success', label: '480-800'},
        ],
        formatter: bandsFormatter
    },
    // SATScores.Math
    {
        key: 'SATScores.Math',
        showInGrades: [9, 10, 11, 12],
        displayName: 'SAT Math',
        userDescription: 'Student\'s SAT Math score.',
        type: 'numeric',
        readOnly: true,
        logField: false,
        bands: [
            // Using benchmark values from College Board
            // https://satsuite.collegeboard.org/k12-educators/about/understand-scores-benchmarks/benchmarks
            {low: -1, high: 500, style: 'warning', label: '200-500'},
            {low: 510, high: 520, style: 'info', label: '510-520'},
            {low: 530, high: 1000, style: 'success', label: '530-800'},
        ],
        formatter: bandsFormatter,
    },
    // ADA
    {
        key: 'ADA', 
        showInGrades: [9, 10, 11, 12],
        displayName: 'Avg. Daily Attendance',
        userDescription: 'Student\'s average daily attendance so far this year, based on school attendance.',
        type: 'numeric',
        displayType: 'percentage',
        logField: false,
        readOnly: true,
        bands: ADABands,
        formatter: ADAbandsFormatter,
        profileFormatter: ADAProfileFormatter
    },
    // Q1Absences
    {
        key: 'Q1Absences', 
        showInGrades: [9, 10, 11, 12],
        displayName: 'Q1 Absences',
        userDescription: 'Student absences for quarter 1',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: absenceBands,
        formatter: bandsFormatter,
    },
    // Q2Absences
    {
        key: 'Q2Absences', 
        showInGrades: [9, 10, 11, 12],
        displayName: 'Q2 Absences',
        userDescription: 'Student absences for quarter 2',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: absenceBands,
        formatter: bandsFormatter,
    },
    // Q3Absences
    {
        key: 'Q3Absences', 
        showInGrades: [9, 10, 11, 12],
        displayName: 'Q3 Absences',
        userDescription: 'Student absences for quarter 3',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: absenceBands,
        formatter: bandsFormatter,
    },
    // Q4Absences
    {
        key: 'Q4Absences', 
        showInGrades: [9, 10, 11, 12],
        displayName: 'Q4 Absences',
        userDescription: 'Student absences for quarter 4',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: absenceBands,
        formatter: bandsFormatter,
    },
    // Q1Suspensions
    {
        key: 'Q1Suspensions', 
        width: 150,
        showInGrades: [9, 10, 11, 12],
        displayName: 'Q1 Suspensions',
        userDescription: 'Number of suspensions for quarter 1',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: suspensionsBands,
        formatter: bandsFormatter,
    },
    // Q2Suspensions
    {
        key: 'Q2Suspensions', 
        width: 150,
        showInGrades: [9, 10, 11, 12],
        displayName: 'Q2 Suspensions',
        userDescription: 'Number of suspensions for quarter 2',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: suspensionsBands,
        formatter: bandsFormatter,
    },
    // Q3Suspensions
    {
        key: 'Q3Suspensions', 
        width: 150,
        showInGrades: [9, 10, 11, 12],
        displayName: 'Q3 Suspensions',
        userDescription: 'Number of suspensions for quarter 3',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: suspensionsBands,
        formatter: bandsFormatter,
    },
    // Q4Suspensions
    {
        key: 'Q4Suspensions', 
        width: 150,
        showInGrades: [9, 10, 11, 12],
        displayName: 'Q4 Suspensions',
        userDescription: 'Number of suspensions for quarter 4',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: suspensionsBands,
        formatter: bandsFormatter,
    },    
    // _4YearStepsDone
    {
        displayName: '4-Year College Steps Done',
        width: 150,
        userDescription: 'How many steps the student has completed towards applying to 4-year college.',
        key: '_4YearStepsDone',
        showInGrades: [11, 12],
        type: 'numeric',
        logField: false,
        readOnly: true,
        mutator: mutators.fourYearStepsDone,
        bands: [
            {low: -1, high: 0, style: 'warning', label: '0'},
            {low: 1, high: 3, style: 'info', label: '1-3'},
            {low: 4, high: 4, style: 'gradeB', label: '4'},
            {low: 5, high: 5, style: 'success', label: '5'},
        ],
        formatter: bandsFormatter,
    },
    // intendedMajor
    {
        key: 'intendedMajor',
        showInGrades: [11, 12],
        displayName: 'Intended Major/Program',
        userDescription: 'Student\'s intended major. This field may be updated by counselors.',
        type: 'category',
        readOnly: false,
        formatter: logFormatter,
        sorter: (a, b) => logSort(a, b),
        logField: true,
        width: 150,
    },
    // summerCollegePrepInterest
    {
        key: 'summerCollegePrepInterest',
        width: 150,
        showInGrades: [11, 12],
        displayName: 'Summer Academy',
        userDescription: 'Student is interested in attending Summer Prep Academy.',
        type: 'boolean',
        options: {
            true: 'Plans to do summer prep',
            false: 'No plans to do summer prep'
        },
        readOnly: false,
        formatter: ({cell, colors}) => formatters.checkify(getFirst(cell), colors),
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // summerBridgeEnrollment
    {
        key: 'summerBridgeEnrollment',
        width: 150,
        showInGrades: [8, 9],
        displayName: 'Summer Bridge Enrollment',
        userDescription: 'Student is enrolled in Summer Bridge.',
        type: 'boolean',
        options: {
            true: 'Enrolled',
            false: 'Not enrolled'
        },
        readOnly: true,
        formatter: ({cell, colors}) => formatters.checkify(getFirst(cell), colors),
        sorter: (a, b) => logSort(a, b),
        logField: true,
        hideInCounselorView: true,        
    },
    // FAFSAIntention
    {
        key: 'FAFSAIntention',
        showInGrades: [11, 12],
        displayName: 'FAFSA Intention',
        userDescription: 'Student intends to complete the FAFSA.',
        type: 'boolean',
        options: {
            true: 'Plans to complete FAFSA',
            false: 'No plans to complete FAFSA'
        },
        readOnly: false,
        formatter: ({cell, colors}) => formatters.checkify(getFirst(cell), colors),
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // noFAFSAIntentionExplanation
    {
        key: 'noFAFSAIntentionExplanation',
        showInGrades: [11, 12],
        displayName: 'No FAFSA Explanation',
        userDescription: 'Student\'s reason for planning not to complete the FAFSA.',
        type: 'unique',
        readOnly: false,
        formatter: logFormatter,
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // collegeCommitment
    {
        key: 'collegeCommitment',
        showInGrades: [11, 12],
        displayName: 'Committed College',
        userDescription: 'This lists the college that the student plans to attend, after being accepted.',
        type: 'category',
        readOnly: true,
        victory: value => !!value && (value !== 'No commitment'),
        formatter: ({cell, victory, colors}) => {
            let value = getFirst(cell);
            if (value === '') value = 'No commitment';
            return formatters.badgify(value, victory(value), colors);
        },
        sorter: (a, b) => logSort(a, b),
        logField: true,
    },
    // _collegeAppsSubmitted
    {
        key: '_collegeAppsSubmitted',
        showInGrades: [11, 12],
        displayName: '# of Applications',
        userDescription: 'The number of applications submitted by each student.',
        type: 'numeric',
        logField: false,
        mutator: mutators.collegeAppsSubmitted,
        readOnly: true,
        bands: [
            {low: -1, high: 0, style: 'warning', textColor: 'bandTextLight', label: '0'},
            {low: 1, high: 1, style: 'greenBand1', textColor: 'bandTextDark', label: '1'},
            {low: 2, high: 5, style: 'greenBand3', textColor: 'bandTextDark', label: '2-5'},
            {low: 6, high: 10, style: 'greenBand5', textColor: 'bandTextDark', label: '6-10'},
            {low: 11, high: 1000, style: 'greenBand7', textColor: 'bandTextLight', label: '11+'},
        ],
        formatter: bandsFormatter
    },
    // careerInventory
    {
        key: 'careerInventory',
        showInGrades: [11, 12],
        displayName: 'Career Inventory',
        userDescription: 'This field shows whether student has completed a career inventory.',
        type: 'category',
        readOnly: false,
        sorter: (a, b) => logSort(a, b),
        logField: true,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        formatter: stepFormatter,
    },
    // navianceCollegeList
    {
        key: 'navianceCollegeList',
        showInGrades: [11, 12],
        displayName: 'Naviance College List',
        userDescription: 'This field shows whether student has entered a college list in Naviance.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // FAFSAStatus
    {
        key: 'FAFSAStatus',
        showInGrades: [11, 12],
        displayName: 'FAFSA',
        userDescription: 'Student\'s progress in completing the FAFSA.',
        type: 'category',
        readOnly: false,
        options: {
            'not started': {displayValue: 'Not Started', style: 'warning'},
            'in progress': {displayValue: 'In Progress', style: 'info'},
            'completed': {displayValue: 'Completed', style: 'success'},
            'N/A': {displayValue: 'N/A', style: 'contrastHigh'}
        },
        order: ['Not started', 'In progress', 'Completed', 'N/A'],
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // FAFSAStateStatus
    {
        key: 'FAFSAStateStatus',
        showInGrades: [11, 12],
        displayName: 'EdSight Data',
        userDescription: "Student’s FAFSA status, according to the CT State Department of Education.",
        type: 'category',
        readOnly: true,
        options: {
            'Not Submitted': {displayValue: 'Not Submitted', style: 'warning'},
            'Incomplete': {displayValue: 'Incomplete', style: 'info'},
            'Complete': {displayValue: 'Complete', style: 'success'},
        },
        order: ['Not Submitted', 'Incomplete', 'Complete'],
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: ({cell, options, colors}) => {
            const value = getFirst(cell)
            return formatters.customStyle(value, options, colors);
        }
    },
    // FSAId
    {
        key: 'FSAId',
        showInGrades: [11, 12],
        displayName: 'FSA ID',
        userDescription: 'Whether or not students have a Federal Student Aid ID number.',
        type: 'category',
        readOnly: false,
        options: {
            'not started': {displayValue: 'Not Started', style: 'warning'},
            'in progress': {displayValue: 'In Progress', style: 'info'},
            'completed': {displayValue: 'Completed', style: 'success'},
            'N/A': {displayValue: 'N/A', style: 'contrastHigh'}
        },
        order: ['Not started', 'In progress', 'Completed', 'N/A'],
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // collegeApps
    {
        key: 'collegeApps',
        showInGrades: [11, 12],
        displayName: 'App Status',
        userDescription: 'This field shows whether or not students have submitted college applications.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // studentRequestedRecs
    {
        key: 'studentRequestedRecs',
        width: 150,
        showInGrades: [11, 12],
        displayName: 'Student Requested Recs',
        userDescription: 'This field shows whether students have requested letters of recommendation.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // teacherSubmittedRecs
    {
        key: 'teacherSubmittedRecs',
        width: 150,
        showInGrades: [11, 12],
        displayName: 'Teachers Submitted Recs',
        userDescription: 'This field shows whether teachers have completed letters of recommendation.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // _transcriptsSent
    {
        key: '_transcriptsSent',
        showInGrades: [11, 12],
        displayName: '# Transcripts Sent',
        userDescription: 'This field shows the number of transcripts submitted to colleges / universities through Naviance.',
        type: 'numeric',
        readOnly: true,
        logField: false,
        mutator: mutators.transcriptsSent
    },
    // sentTranscripts
    {
        key: 'sentTranscripts',
        showInGrades: [11, 12],
        displayName: 'Transcripts Sent (Trade)',
        userDescription: 'Counselors may update this field to indicate whether transcripts were sent to trade/technical programs.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // immunizationForm
    {
        key: 'immunizationForm',
        showInGrades: [11, 12],
        displayName: 'Immunization Form',
        userDescription: 'Student\'s progress in submitting paperwork regarding required immunizations.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // ASVAB
    {
        key: 'ASVAB',
        showInGrades: [11, 12],
        displayName: 'ASVAB Exam',
        userDescription: 'Students has completed the ASVAB exam necessary to enlist in the military.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // militaryRecruiter
    {
        key: 'militaryRecruiter',
        showInGrades: [11, 12],
        displayName: 'Military Recruiter',
        userDescription: 'Students has met with a military recruiter.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // militaryBranchCommitment
    {
        key: 'militaryBranchCommitment',
        width: 150,
        showInGrades: [11, 12],
        displayName: 'Military Branch Commitment',
        userDescription: 'Student has committed to a specific branch of the military.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // militaryBranchInterest
    {
        key: 'militaryBranchInterest',
        showInGrades: [11, 12],
        displayName: 'Military Branch',
        userDescription: 'Military branch in which the student plans to enlist',
        type: 'category',
        readOnly: false,
        options: {
            'Army': { displayValue: 'Army'},
            'Navy': { displayValue: 'Navy'},
            'Air Force': { displayValue: 'Air Force'},
            'Marines': { displayValue: 'Marines'},
            'Coast Guard': { displayValue: 'Coast Guard'},
            'Army National Guard': { displayValue: 'Army National Guard'},
            'Air National Guard': { displayValue: 'Air National Guard'},
            'Space Force': { displayValue: 'Space Force'},
            'None': { displayValue: 'None'},
            null: { displayValue: 'No data', style: 'contrastLow'}
        },
        order: ['Army', 'Navy', 'Air Force', 'Marines', 'Coast Guard', 'Army National Guard', 'Air National Guard', 'Space Force', 'None'],
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: logFormatter
    },
    // workPermit
    {
        key: 'workPermit',
        showInGrades: [11, 12],
        displayName: 'Work Permit',
        userDescription: 'Student has obtained a work permit.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // resume
    {
        key: 'resume',
        showInGrades: [11, 12],
        displayName: 'Resume',
        userDescription: 'Student has developed a resume.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // jobApplications
    {
        key: 'jobApplications',
        showInGrades: [11, 12],
        displayName: 'Job Applications',
        userDescription: 'Student has submitted applications for employment.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // employment
    {
        key: 'employment',
        showInGrades: [11, 12],
        displayName: 'Employment',
        userDescription: 'Student has confirmed plans for postsecondary employment.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // tradeProgramEnrollment
    {
        key: 'tradeProgramEnrollment',
        width: 150,
        showInGrades: [11, 12],
        displayName: 'Trade Program Enrollment',
        userDescription: 'Student has enrolled in a trade or technical program.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value === 'completed',
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    },
    // postSecondaryRegistration
    {
        key: 'postSecondaryRegistration',
        showInGrades: [11, 12],
        displayName: 'Registration',
        userDescription: 'Student has registered for 2-year 4-year, Trade School and Military programs.',
        type: 'category',
        readOnly: false,
        options: {
            true: 'Registered',
            false: 'Not registered',
        },
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: ({cell, colors}) => {
            const value = getFirst(cell);
            return formatters.checkify(value, colors);
        }
    },
    // postSecondaryOrientation
    {
        key: 'postSecondaryOrientation',
        showInGrades: [11, 12],
        displayName: 'Orientation',
        userDescription: 'Student has been oriented for for 2-year 4-year, and Trade School programs.',
        type: 'category',
        readOnly: false,
        options: {
            true: 'Oriented',
            false: 'Not Oriented',
        },
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: ({cell, colors}) => {
            const value = getFirst(cell);
            return formatters.checkify(value, colors);
        },
    },
    // lastNavianceLogin
    {
        key: 'lastNavianceLogin',
        showInGrades: [11, 12],
        displayName: 'Naviance Login',
        userDescription: 'Student\'s last log in date for the Naviance platform.',
        readOnly: true,
        type: 'date',
        logField: false,
    },
    // g8RiskAndOpportunityMarch.riskAndOpportunity
    {
        key: 'g8RiskAndOpportunityMarch.riskAndOpportunity',
        showInGrades: [8],
        displayName: 'G8 R&O Status (March)',
        logField: false,
        width: 200,
        userDescription: 'Student Grade 8 Risk & Opportunity status calculated in mid-March based on GPA and Avg. Daily Attendance.',
        type: 'category',
        readOnly: true,
        options: {
            'Repeater': {displayValue: 'Repeater', style: 'secondary'},
            'High Risk': {displayValue: 'High Risk', style: 'warning'},
            'Vulnerable': {displayValue: 'Vulnerable', style: 'info'},
            'Opportunity': {displayValue: 'Opportunity', style: 'gradeB'},
            'High Opportunity': {displayValue: 'High Opportunity', style: 'success'},
            'Missing Academic Data': {displayValue: 'Missing Academic Data', style: 'contrastMedium'},
            'Missing Attendance Data': {displayValue: 'Missing Attendance Data', style: 'contrastMedium'},
        },
        order: ['Missing Academic Data', 'Missing Attendance Data', 'Repeater', 'High Risk', 'Vulnerable', 'Opportunity', 'High Opportunity'],
        sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
            const list = {'High Opportunity': 6, 'Opportunity': 5, 'Vulnerable': 4, 'High Risk': 3, 'Repeater': 2, 'Missing Academic Data': 1, 'Missing Attendance Data': 0};
            if (list[a] === undefined) return -1;
            if (list[b] === undefined) return 1;
            const result = 'asc' ?
                list[a] - list[b]:
                list[b] - list[a];
            return result;
        },
        formatter: ({cell, options, colors}) => {
            const value = cell.getValue();
            return formatters.customStyle(value, options, colors);
        },
    },
    // g8RiskAndOpportunityMay.riskAndOpportunity
    {
        key: 'g8RiskAndOpportunityMay.riskAndOpportunity',
        showInGrades: [8],
        displayName: 'G8 R&O Status (May)',
        logField: false,
        width: 200,
        userDescription: 'Student Grade 8 Risk & Opportunity status calculated in mid-May based on GPA and Avg. Daily Attendance.',
        type: 'category',
        readOnly: true,
        options: {
            'Repeater': {displayValue: 'Repeater', style: 'secondary'},
            'High Risk': {displayValue: 'High Risk', style: 'warning'},
            'Vulnerable': {displayValue: 'Vulnerable', style: 'info'},
            'Opportunity': {displayValue: 'Opportunity', style: 'gradeB'},
            'High Opportunity': {displayValue: 'High Opportunity', style: 'success'},
            'Missing Academic Data': {displayValue: 'Missing Academic Data', style: 'contrastMedium'},
            'Missing Attendance Data': {displayValue: 'Missing Attendance Data', style: 'contrastMedium'},
        },
        order: ['Missing Academic Data', 'Missing Attendance Data', 'Repeater', 'High Risk', 'Vulnerable', 'Opportunity', 'High Opportunity'],
        sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
            const list = {'High Opportunity': 6, 'Opportunity': 5, 'Vulnerable': 4, 'High Risk': 3, 'Repeater': 2, 'Missing Academic Data': 1, 'Missing Attendance Data': 0};
            if (list[a] === undefined) return -1;
            if (list[b] === undefined) return 1;
            const result = 'asc' ?
                list[a] - list[b]:
                list[b] - list[a];
            return result;
        },
        formatter: ({cell, options, colors}) => {
            const value = cell.getValue();
            return formatters.customStyle(value, options, colors);
        },
    },
    // g9RiskAndOpportunity.riskAndOpportunity
    {
        key: 'g9RiskAndOpportunity.riskAndOpportunity',
        showInGrades: [9, 10, 11, 12],
        displayName: 'G9 R&O Status',
        logField: false,
        width: 200,
        userDescription: 'Student Risk & Opportunity status calculated around August before the start of the 9th grade year, based on 8th grade GPA and average daily attendance.',
        type: 'category',
        readOnly: true,
        options: {
            'Repeater': {displayValue: 'Repeater', style: 'secondary'},
            'High Risk': {displayValue: 'High Risk', style: 'warning'},
            'Vulnerable': {displayValue: 'Vulnerable', style: 'info'},
            'Opportunity': {displayValue: 'Opportunity', style: 'gradeB'},
            'High Opportunity': {displayValue: 'High Opportunity', style: 'success'},
            'Missing Academic Data': {displayValue: 'Missing Academic Data', style: 'contrastMedium'},
            'Missing Attendance Data': {displayValue: 'Missing Attendance Data', style: 'contrastMedium'},
        },
        order: ['Missing Academic Data', 'Missing Attendance Data', 'Repeater', 'High Risk', 'Vulnerable', 'Opportunity', 'High Opportunity'],
        sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
            const list = {'High Opportunity': 6, 'Opportunity': 5, 'Vulnerable': 4, 'High Risk': 3, 'Repeater': 2, 'Missing Academic Data': 1, 'Missing Attendance Data': 0};
            if (list[a] === undefined) return -1;
            if (list[b] === undefined) return 1;
            const result = 'asc' ?
                list[a] - list[b]:
                list[b] - list[a];
            return result;
        },
        formatter: ({cell, options, colors}) => {
            const value = cell.getValue();
            return formatters.customStyle(value, options, colors);
        },
    },
    // g8RiskAndOpportunityMay.ADA
    {
      key: 'g8RiskAndOpportunityMay.ADA',
      showInGrades: [8],
      displayName: 'G8 R&O Avg. Daily Attend.',
      userDescription: 'Student grade 8 average daily attendance from the May R&O process',
      type: 'numeric',
      displayType: 'percentage',
      logField: false,
      readOnly: true,
      bands: ADABands,
      formatter: ADAbandsFormatter,
      profileFormatter: ADAProfileFormatter
    },
    // g8RiskAndOpportunityMarch.ADA
    {
        key: 'g8RiskAndOpportunityMarch.ADA',
        showInGrades: [8],
        displayName: 'G8 R&O Avg. Daily Attend.',
        userDescription: 'Student grade 8 average daily attendance from the March R&O process',
        type: 'numeric',
        displayType: 'percentage',
        logField: false,
        readOnly: true,
        bands: ADABands,
        formatter: ADAbandsFormatter,
        profileFormatter: ADAProfileFormatter
    },
    // g9RiskAndOpportunity.ADA
    {
        key: 'g9RiskAndOpportunity.ADA',
        showInGrades: [9, 10, 11, 12],
        displayName: 'G9 R&O Avg. Daily Attend.',
        userDescription: 'Student grade 8 end of year average daily attendance, calculated in August before grade 9',
        type: 'numeric',
        displayType: 'percentage',
        logField: false,
        readOnly: true,
        bands: ADABands,
        formatter: ADAbandsFormatter,
        profileFormatter: ADAProfileFormatter
    },
    // g8RiskAndOpportunityMay.GPA
    {
        key: 'g8RiskAndOpportunityMay.GPA',
        showInGrades: [8],
        displayName: 'G8 R&O GPA',
        userDescription: 'Student grade 8 grade point average in May',
        logField: false,
        type: 'numeric',
        readOnly: true,
        bands: GPABands,
        formatter: bandsFormatter
    },
    // g8RiskAndOpportunityMarch.GPA
    {
        key: 'g8RiskAndOpportunityMarch.GPA',
        showInGrades: [8],
        displayName: 'G8 R&O GPA',
        userDescription: 'Student grade 8 grade point average calculated in March',
        logField: false,
        type: 'numeric',
        readOnly: true,
        bands: GPABands,
        formatter: bandsFormatter
    },
    // g9RiskAndOpportunity.GPA
    {
        key: 'g9RiskAndOpportunity.GPA',
        showInGrades: [9, 10, 11, 12],
        displayName: 'G9 R&O GPA',
        userDescription: 'Student grade 8 end of year grade point average, calculated at the begnning of grade 9',
        logField: false,
        type: 'numeric',
        readOnly: true,
        bands: GPABands,
        formatter: bandsFormatter
    },
    // g8RiskAndOpportunityMay.suspensions
    {
        key: 'g8RiskAndOpportunityMay.suspensions',
        showInGrades: [8],
        width: 150,
        displayName: 'G8 R&O Suspensions',
        userDescription: 'Student grade 8 end of year number of suspensions',
        logField: false,
        type: 'numeric',
        readOnly: true,
        bands: suspensionsBands,
        formatter: bandsFormatter,
    },
    // g8RiskAndOpportunityMarch.suspensions
    {
        key: 'g8RiskAndOpportunityMarch.suspensions',
        showInGrades: [8],
        width: 150,
        displayName: 'G8 R&O Suspensions',
        userDescription: 'Student grade 8 end of year number of suspensions',
        logField: false,
        type: 'numeric',
        readOnly: true,
        bands: suspensionsBands,
        formatter: bandsFormatter,
    },
    // g9RiskAndOpportunity.suspensions
    {
        key: 'g9RiskAndOpportunity.suspensions',
        showInGrades: [9, 10, 11, 12],
        width: 150,
        displayName: 'G9 R&O Suspensions',
        userDescription: 'Student grade 8 end of year number of suspensions, calculated at the begnning of grade 9',
        logField: false,
        type: 'numeric',
        readOnly: true,
        bands: suspensionsBands,
        formatter: bandsFormatter,
    },
    // suspensions
    {
        key: 'suspensions',
        showInGrades: [9, 10, 11, 12],
        width: 150,
        displayName: 'Suspensions',
        userDescription: 'Student number of suspensions for this academic year',
        logField: false,
        type: 'numeric',
        readOnly: true,
        bands: suspensionsBands,
        formatter: bandsFormatter,
    },
    // g8RiskAndOpportunityMay.riskFactors
    {
        key: 'g8RiskAndOpportunityMay.riskFactors',
        width: 150,
        showInGrades: [8],
        displayName: 'G8 R&O Risk Factors',
        userDescription: 'Number of grade 8 risk factors (Avg. Daily Attendance < 90, 1+ suspension, GPA < 3.0.)',
        logField: false,
        type: 'numeric',
        readOnly: true,
        formatter: bandsFormatter,
        bands: [
            {low: -1, high: 0, style: 'success', label: '0 risk factors'},
            {low: 1, high: 1, style: 'gradeB', label: '1 risk factors'},
            {low: 2, high: 2, style: 'info', label: '2 risk factors'},
            {low: 3, high: 3, style: 'warning', label: '3 risk factors'},
        ]
    },
    // g8RiskAndOpportunityMarch.riskFactors
    {
        key: 'g8RiskAndOpportunityMarch.riskFactors',
        width: 150,
        showInGrades: [8],
        displayName: 'G8 R&O Risk Factors',
        userDescription: 'Number of grade 8 risk factors (Avg. Daily Attendance < 90, 1+ suspension, GPA < 3.0.)',
        logField: false,
        type: 'numeric',
        readOnly: true,
        formatter: bandsFormatter,
        bands: [
            {low: -1, high: 0, style: 'success', label: '0 risk factors'},
            {low: 1, high: 1, style: 'gradeB', label: '1 risk factors'},
            {low: 2, high: 2, style: 'info', label: '2 risk factors'},
            {low: 3, high: 3, style: 'warning', label: '3 risk factors'},
        ]
    },
    // g9RiskAndOpportunity.riskFactors
    {
        key: 'g9RiskAndOpportunity.riskFactors',
        width: 150,
        showInGrades: [9, 10, 11, 12],
        displayName: 'G9 R&O Risk Factors',
        userDescription: 'Number of grade 8 risk factors (Avg. Daily Attendance < 90, 1+ suspension, GPA < 3.0.), calculated at the begnning of grade 9',
        logField: false,
        type: 'numeric',
        readOnly: true,
        formatter: bandsFormatter,
        bands: [
            {low: -1, high: 0, style: 'success', label: '0 risk factors'},
            {low: 1, high: 1, style: 'gradeB', label: '1 risk factors'},
            {low: 2, high: 2, style: 'info', label: '2 risk factors'},
            {low: 3, high: 3, style: 'warning', label: '3 risk factors'},
        ]
    },
    // middleSchool
    {
        key: 'middleSchool',
        showInGrades: [8],
        displayName: 'Middle School',
        userDescription: 'Student middle school',
        type: 'category',
        logField: false,
        readOnly: true
    },
    // g9ADA
    {
        key: 'g9ADA',
        showInGrades: [9, 10, 11, 12],
        displayName: 'G9 Avg. Daily Attend.',
        userDescription: 'Student grade 9 end of year average daily attendance',
        type: 'numeric',
        displayType: 'percentage',
        logField: false,
        readOnly: true,
        bands: ADABands,
        formatter: ADAbandsFormatter,
        profileFormatter: ADAProfileFormatter
    },
    // g9GPA
    {
        key: 'g9GPA',
        showInGrades: [9, 10, 11, 12],
        displayName: 'Grade 9 GPA',
        userDescription: 'Unofficial student cumulative GPA. This GPA is unweighted, i.e. honors classes are not given extra weight. Do not use for official reporting purposes.',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: GPABands,
        formatter: bandsFormatter
    },
    // {
    //     key: 'g9Suspensions',
    //     width: 150,
    //     showInGrades: [9, 10, 11, 12],
    //     displayName: 'Grade 9 Suspensions',
    //     userDescription: 'Student grade 9 end of year number of suspensions',
    //     type: 'numeric',
    //     logField: false,
    //     readOnly: true,
    //     bands: suspensionsBands,
    //     formatter: bandsFormatter,
    // },
    // g10ADA
    {
        key: 'g10ADA',
        showInGrades: [10, 11, 12],
        displayName: 'G10 Avg. Daily Attend.',
        userDescription: 'Student grade 10 end of year average daily attendance',
        logField: false,
        type: 'numeric',
        displayType: 'percentage',
        readOnly: true,
        bands: ADABands,
        formatter: ADAbandsFormatter,
        profileFormatter: ADAProfileFormatter
    },
    // g10GPA
    {
        key: 'g10GPA',
        showInGrades: [10, 11, 12],
        displayName: 'Grade 10 GPA',
        userDescription: 'Unofficial student cumulative GPA. This GPA is unweighted, i.e. honors classes are not given extra weight. Do not use for official reporting purposes.',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: GPABands,
        formatter: bandsFormatter
    },
    // {
    //     key: 'g10Suspensions',
    //     width: 150,
    //     showInGrades: [10, 11, 12],
    //     displayName: 'Grade 10 Suspensions',
    //     userDescription: 'Student grade 10 end of year number of suspensions',
    //     type: 'numeric',
    //     logField: false,
    //     readOnly: true,
    //     bands: suspensionsBands,
    //     formatter: bandsFormatter,
    // },
    // g11ADA
    {
        key: 'g11ADA',
        showInGrades: [11, 12],
        displayName: 'G11 Avg. Daily Attend.',
        userDescription: 'Student grade 11 end of year average daily attendance',
        type: 'numeric',
        displayType: 'percentage',
        logField: false,
        readOnly: true,
        bands: ADABands,
        formatter: ADAbandsFormatter,
        profileFormatter: ADAProfileFormatter
    },
    // g11GPA
    {
        key: 'g11GPA',
        showInGrades: [11, 12],
        displayName: 'Grade 11 GPA',
        userDescription: 'Unofficial student cumulative GPA. This GPA is unweighted, i.e. honors classes are not given extra weight. Do not use for official reporting purposes.',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: GPABands,
        formatter: bandsFormatter
    },
    // {
    //     key: 'g11Suspensions',
    //     width: 150,
    //     showInGrades: [11, 12],
    //     displayName: 'Grade 11 Suspensions',
    //     userDescription: 'Student grade 11 end of year number of suspensions',
    //     type: 'numeric',
    //     logField: false,
    //     readOnly: true,
    //     bands: suspensionsBands,
    //     formatter: bandsFormatter,
    // },
    // g12ADA    
    {
        key: 'g12ADA',
        showInGrades: [12],
        displayName: 'G12 Avg. Daily Attend.',
        userDescription: 'Student grade 12 end of year average daily attendance',
        logField: false,
        type: 'numeric',
        displayType: 'percentage',
        hideInFilters: true,
        readOnly: true,
        bands: ADABands,
        formatter: ADAbandsFormatter,
        profileFormatter: ADAProfileFormatter
    },
    // g12GPA
    {
        key: 'g12GPA',
        showInGrades: [12],
        displayName: 'Grade 12 GPA',
        userDescription: 'Unofficial student cumulative GPA. This GPA is unweighted, i.e. honors classes are not given extra weight. Do not use for official reporting purposes.',
        type: 'numeric',
        logField: false,
        readOnly: true,
        bands: GPABands,
        formatter: bandsFormatter
    },
    // {
    //     key: 'g12Suspensions',
    //     width: 150,
    //     showInGrades: [12],
    //     displayName: 'Grade 12 Suspensions',
    //     userDescription: 'Student grade 12 end of year number of suspensions',
    //     logField: false,
    //     type: 'numeric',
    //     readOnly: true,
    //     bands: suspensionsBands,
    //     formatter: bandsFormatter,
    // },
    // _daysSinceLastCheckin
    {
        key: '_daysSinceLastCheckin', 
        width: 150,
        showInGrades: [9],
        displayName: 'Days since last check-in',
        userDescription: 'Days since last personal check-in with OTC',
        type: 'numeric',
        logField: false,
        readOnly: true,
        mutator: mutators.daysSinceLastCheckIn,
        bands: checkInBands,
        formatter: bandsFormatter,
    },
    // application essay
    {
        key: 'applicationEssay',
        showInGrades: [11, 12],
        displayName: 'Essay',
        userDescription: 'Student\'s progress on completing an application essay',
        type: 'category',
        readOnly: false,
        options: {
            'not started': {displayValue: 'Not Started', style: 'warning'},
            'in progress': {displayValue: 'In Progress', style: 'info'},
            'completed': {displayValue: 'Completed', style: 'success'},
            'N/A': {displayValue: 'N/A', style: 'contrast-high'}
        },
        order: ['Not started', 'In progress', 'Completed', 'N/A'],
        sorter: (a, b) => logSort(a, b),
        logField: true,
        formatter: stepFormatter
    }
];

export const [fields, fieldGroups] = processFieldsAndGroups({fields: _fields, groups});
